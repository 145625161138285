import React from "react";
import AboutAreaTwo from "../components/AboutAreaTwo";
import BannerTwo from "../components/BannerTwo";
import ContactAreaTwo from "../components/ContactAreaTwo";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";
import TestimonialOne from "../components/TestimonialOne";
import WorkProcessTwo from "../components/WorkProcessTwo";

const Home = () => {
  return (
    <>
      {/* Navigation Bar Two */}
      <NavbarTwo />

      {/* Banner Two */}
      <div id="home">
        <BannerTwo />
      </div>

      {/* About Area Two */}
      <div id="about">
        <AboutAreaTwo />
      </div>

      {/* Contact Area Two */}
      <div id="services">
        <br/>
        <ContactAreaTwo />
      </div>

      <WorkProcessTwo />

      {/* Testimonial One */}
      <div id="testimonials">
        <TestimonialOne />
      </div>

    

      {/* Footer Two */}
      <div id="contact">
      <FooterTwo />
      </div>
    </>
  );
};

export default Home;
