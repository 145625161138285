import React from "react";
import { FaAngleLeft, FaAngleRight, FaStar } from "react-icons/fa";
import Slider from "react-slick";
const TestimonialOne = () => {
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <FaAngleRight className={className} onClick={onClick} />;
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <FaAngleLeft className={className} onClick={onClick} />;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      {/* =================== Testimonial One Start ===================*/}
      <div
        className='testimonial-area pd-top-120 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/11.png")' }}
      >
        <div className='container'>
          <div className='section-title'>
            <h6 className='sub-title'>TESTIMONIAL</h6>
            <h2 className='title'>Messages from Stakeholders</h2>
          </div>
          <div className='testimonial-slider-1  slider-control-round slider-control-dots slider-control-right-top'>
            <Slider {...settings}>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>Department of Fisheries</h5>
                 
                  <div className='icon mb-2'>
                    <img src='assets/img/icon/25.png' alt='img' />
                  </div>
                  <p>
                  The adoption of the AI-Maluwa app ensures transparency across the seafood supply chain. It empowers fishers and processors to meet international quality standards, strengthening the entire industry.
                  </p>
                 
                  <div className='thumb'>
                    <img src='assets/img/testimonial/1.png' alt='img' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>FAO Representative</h5>
                  
                  <div className='icon mb-2'>
                    <img src='assets/img/icon/25.png' alt='img' />
                  </div>
                  <p>
                  The AI-Maluwa project aligns with FAO’s mission to promote sustainable fishing practices. It addresses the industry's core challenges by enhancing efficiency, fairness, and environmental responsibility.
                  </p>
                 
                  <div className='thumb'>
                    <img src='assets/img/testimonial/2.png' alt='img' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>Boat Owner</h5>
                 
                  <div className='icon mb-2'>
                    <img src='assets/img/icon/25.png' alt='img' />
                  </div>
                  <p>
                  The AI-Maluwa app supports me in making the right decisions about the quality of my catch. It provides reliable insights, which help ensure fair pricing and maintain good relationships with buyers.
                  </p>
                 
                  <div className='thumb'>
                    <img src='assets/img/testimonial/3.png' alt='img' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>Skipper</h5>
                 
                  <div className='icon mb-2'>
                    <img src='assets/img/icon/25.png' alt='img' />
                  </div>
                  <p>
                  This app makes my job easier by providing consistent quality reports for Yellowfin tuna. It’s user-friendly and gives me the confidence that I am supplying high-quality fish to the market.
                  </p>
                  
                  <div className='thumb'>
                    <img src='assets/img/testimonial/4.png' alt='img' />
                  </div>
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>CEO, GeoEDGE</h5>
                 
                  <div className='icon mb-2'>
                    <img src='assets/img/icon/25.png' alt='img' />
                  </div>
                  <p>
                  AI-Maluwa reflects GeoEDGE’s commitment to using cutting-edge technology to solve real-world challenges. By blending AI with local expertise, we are setting a new standard for quality assurance in Sri Lanka’s fisheries industry.                  </p>
                  
                  <div className='thumb'>
                    <img src='assets/img/testimonial/5.png' alt='img' />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* =================== Testimonial One End ===================*/}
    </>
  );
};

export default TestimonialOne;
