import React from "react";
import { FaCheckCircle, FaPlus, FaDownload } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaTwo = () => {
  return (
    <>
      {/* =============== About Area Two End ===============*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='assets/img/about/11.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>ABOUT US</h6>
                <h2 className='title'>
                Designing Solutions
to Enhance
Seafood Quality
                </h2>
                <p className='content mb-4'>
                The "AI-Enabled Mobile App for Fish Quality Measurement in Sri Lanka" marks a significant advancement in the seafood industry. Funded by Norway and coordinated by the Food and Agriculture Organization of the United Nations (FAO), with support from the Department of Fisheries & Aquatic Resources, this initiative was developed by GeoEDGE (Pvt) Ltd. 
                </p>
              
                <p className='content'>
                Its core aim is to empower multiday boat owners, skippers, and fish exporters by enabling them to assess fish quality using AI and machine learning technologies.
                </p>
                <br/>
                <a
                  className='btn btn-border-base mt-70 '
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  href='/#services'
                >
                  Discover More <FaPlus />
                </a>
                <a
                  className='btn btn-border-base mt-70  ml-30'
                  data-aos='fade-right'
                  data-aos-delay='300'
                  data-aos-duration='1500'
                  target="_blank"
                  href='assets/User Manual -Mobile.pdf'
                >
                  Download User Guide <FaDownload />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== About Area Two End ===============*/}
    </>
  );
};

export default AboutAreaTwo;
