import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area footer-area-2 bg-gray mt-0 pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo.png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                  The AI-Enabled Mobile Application for Fish Quality Measurement is revolutionizing quality control processes, specifically designed for analyzing Yellowfin Tuna.
                  </p>
                
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 ps-xl-5'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Quick Link</h4>
                <ul>
                <li>
                    <Link to='/FAQ'>
                      <FaChevronRight /> FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> FAO project link
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaChevronRight /> GeoEDGE Blog post
                    </Link>
                  </li>
                
                </ul>
                <br/>
                <img src='assets/img/app.png' alt='img' className="app-logo" />
              </div>
            </div>
           
            <div className='col-lg-4 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Contact us</h4>
                <div className='widget widget_contact'>
                  <ul className='details'>
                   
                    <li className='mt-3'>
                      <FaPhoneAlt /> +94 777 636 487
                    </li>
                    <li className='mt-2'>
                      <FaEnvelope /> info@geoedge.lk
                    </li>
                  </ul>
                  <ul className='social-media mt-4'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© AIMALUWA.COM 2024 | All Rights Reserved. Developed by <a href="https://geoedge.lk" target="blank">GeoEDGE</a></p>
              </div>
              {/* <div className='col-md-6 text-lg-end'>
               
                <Link to='/Privacy-Policy'>Privacy Policy</Link>
                
              </div>  */}
              <div className='col-md-6 text-lg-end'>
                {/* <a href='#'>Trams &amp; Condition</a> */}
                <a href="https://aimaluwa.com/privacy_policy.html" target="_blank">Privacy Policy</a>
                
              </div>
              
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterTwo;
