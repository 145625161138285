import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/Privacy-Policy";
import FAQ from "./pages/FAQ";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/Privacy-Policy' element={<PrivacyPolicy />} />
        <Route exact path='/FAQ' element={<FAQ />} />
      </Routes>
      <ScrollToTop smooth color='#0088AB' />
    </BrowserRouter>
  );
}

export default App;
