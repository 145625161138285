import React, { useState, useEffect } from "react";
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";

const FAQPage = () => {
  const [language, setLanguage] = useState("en");
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  // Content for each language
  const faqContent = {
    en: [
      {
        question: "How do I create a new account?",
        answer: [
          "Navigate to the 'Sign Up' (or 'Create Account') section.",
          "Provide your Login, First Name, Last Name, Email, and password in the related fields.",
          "Click on the 'Sign Up' button.",
          "Upon successful sign-up, you will be granted access to the Home page."
        ]
      },
      {
        question: "How do I log in to my account?",
        answer: [
          "Go to the 'Login' (or 'Sign In') section.",
          "Provide your username and password in the related fields.",
          "Click on the 'Sign In' button.",
          "A successful login will grant you access to the Home page."
        ]
      },
      {
        question: "What should I do if I forget my password?",
        answer: [
          "Click on the 'Forgot Password' link on the login page.",
          "Follow the instructions to reset your password, usually by entering your email address and following a link sent to your email."
        ]
      },
      {
        question: "How do I start scanning tasks?",
        answer: [
          "After logging in, select the 'Yellowfin Tuna' card on the Fish Selection Screen.",
          "Choose the Scan Option and select 'Eye Option' first.",
          "Follow the popup message instructions to turn on the location.",
          "Capture a clear and focused view of the fish's eye."
        ]
      },
      {
        question: "Can I scan multiple parts of the fish in one session?",
        answer: [
          "Yes, each session has a unique session ID and can be used to scan specific parts such as eyes or flesh.",
          "Multiple images can be added to a single session."
        ]
      },
      {
        question: "How do I continue an existing session?",
        answer: [
          "To continue the same session, click on the 'eye' or others in the expanded bottom sheet.",
          "This will allow you to perform scanning tasks under the same Session ID and location."
        ]
      },
      {
        question: "How do I update my profile information?",
        answer: [
          "Navigate to the top right corner of the screen where your profile icon or username is displayed.",
          "Click on the navigation button and open the dropdown menu.",
          "Select 'Profile Settings' or 'Edit Profile' from the dropdown menu.",
          "On the Profile Settings page, you can update your personal information."
        ]
      },
      {
        question: "How do I log out of the system?",
        answer: [
          "Navigate to the top right corner of the screen where your profile icon or username is displayed.",
          "Click on the navigation button to open the dropdown menu.",
          "Select the 'Log Out' option from the dropdown menu.",
          "Confirm the logout action if prompted.",
          "You will be logged out of the system and redirected to the login page."
        ]
      }
    ],
    si: [
      {
        question: "නව ගිණුමක් සෑදන්නේ කෙසේද?",
        answer: [
          "'Sign Up' (හෝ 'Create Account') අංශයට පිවිසෙන්න.",
          "ඔබගේ ලොගින්, පළමු නාමය, අවසන් නාමය, ඊ-මේල්, සහ මුරපදය සදහා සම්බන්ධ ක්ෂේත්‍ර තුල පුරවන්න.",
          "'Sign Up' බොත්තම ක්ලික් කරන්න.",
          "සාර්ථකව ලියාපදිංචි වූ පසු, ඔබට මුල් පිටුවට පිවිසිය හැක."
        ]
      },
      {
        question: "ඔබගේ ගිණුමට පිවිසෙන්නේ කෙසේද?",
        answer: [
          "'Login' (හෝ 'Sign In') අංශයට පිවිසෙන්න.",
          "ඔබගේ පරිශීලක නාමය සහ මුරපදය සම්බන්ධ ක්ෂේත්‍ර වල පුරවන්න.",
          "'Sign In' බොත්තම ක්ලික් කරන්න.",
          "සාර්ථකව පිවිසීමෙන් පසු, ඔබට මුල් පිටුවට පිවිසෙන්න."
        ]
      },
      {
        question: "ඔබගේ මුරපදය නැවත සකස් කිරීමට පහත පියවර කරන්නේ කෙසේද?",
        answer: [
          "'Forgot Password' සබැඳිය ක්ලික් කරන්න.",
          "ඔබගේ ඊ-මේල් ලිපිනය ඇතුලත් කරන්න.",
          "ඊ-මේල් එකට එවනු ලැබුණු සබැඳිය අනුගමනය කර, නව මුරපදයක් සකස් කරන්න."
        ]
      },
      {
        question: "ස්කෑන් කිරීමේ කාර්ය ආරම්භ කරන්නේ කෙසේද?",
        answer: [
          "Fish Selection Screen එකේ 'Yellowfin Tuna' කාඩ්පත තෝරන්න.",
          "Scan Option එක තෝරා ගන්නා විට පළමුව 'Eye Option' එක තෝරන්න.",
          "පිටපත් වන පණිවිඩ උපදෙස් අනුගමනය කරන්න.",
          "මාළුගේ ඇස පැහැදිලි හා පැහැදිලිව පිටපත් කිරීමේ පියවර අනුගමනය කරන්න."
        ]
      },
      {
        question: "එක් සැසියකදී මාළුගේ විවිධ කොටස් ස්කෑන් කළ හැකිද?",
        answer: [
          "සෑම සැසියකටම විශේෂ ID එකක් ඇති අතර, මෙම ID එක භාවිතයෙන් ඇස් හෝ මස් වැනි කොටස් ස්කෑන් කරයි.",
          "ඔබට එක් සැසියකට බහු රූප එකතු කළ හැක."
        ]
      },
      {
        question: "මම දැනට පවතින සැසියක් තවදුරටත් කරගෙන යන්නේ කෙසේද?",
        answer: [
          "එම සැසිය තවදුරටත් කරගෙන යාම සඳහා, පහළට විහිදුණු පත්‍රයේ 'eye' හෝ වෙනත් විකල්ප ක්ලික් කරන්න.",
          "මෙය ඔබට එකම සැසියක ID සහ ස්ථානය යටතේ ස්කෑන් කාර්ය සිදු කිරීමට ඉඩ දෙයි."
        ]
      },
      {
        question: "මාගේ පැතිකඩ තොරතුරු යාවත්කාලීන කරන්නේ කෙසේද?",
        answer: [
          "තිරයේ උඩුකුණේ ඇති ඔබේ පැතිකඩ නිරූපණය හෝ පරිශීලක නාමය ඇති ස්ථානයට යන්න.",
          "දිගුවන බොත්තම ක්ලික් කර දිගුවන මෙනුව විවෘත කරන්න.",
          "දිගුවන මෙනුවෙන් 'Profile Settings' හෝ 'Edit Profile' තෝරන්න.",
          "පැතිකඩ සැකසුම් පිටුවේ, ඔබේ පුද්ගලික තොරතුරු යාවත්කාලීන කළ හැක."
        ]
      },
      {
        question: "පද්ධතියෙන් පිටවන්නේ කෙසේද?",
        answer: [
          "තිරයේ උඩුකුණේ ඇති ඔබේ පැතිකඩ නිරූපණය හෝ පරිශීලක නාමය ඇති ස්ථානයට යන්න.",
          "දිගුවන බොත්තම ක්ලික් කර දිගුවන මෙනුව විවෘත කරන්න.",
          "දිගුවන මෙනුවෙන් 'Log Out' විකල්පය තෝරන්න.",
          "ඉල්ලා සිටින ලොග් අව්ට් ක්‍රියාව තහවුරු කරන්න.",
          "ඔබ පද්ධතියෙන් පිටවනු ලැබේ සහ ලොගින් පිටුවට යොමු කෙරේ."
        ]
      }
    ],
    ta: [
      {
        question: "புதிய கணக்கை உருவாக்க எப்படி?",
        answer: [
          "'Sign Up' (அல்லது 'Create Account') பகுதியைச் செல்க.",
          "உங்கள் உள்நுழைவுப் பெயர், முதற் பெயர், மறுபெயர், மின்னஞ்சல் மற்றும் கடவுச்சொல்லை சம்பந்தப்பட்ட புலங்களில் கொடுங்கள்.",
          "'Sign Up' பொத்தானைக் கிளிக் செய்யவும்.",
          "வெற்றிகரமாக பதிவு செய்யப்படும் போது, நீங்கள் முகப்புப் பக்கம் அணுக விருப்பம் பெறுவீர்கள்."
        ]
      },
      {
        question: "நான் என் கணக்கில் உள்நுழைய எப்படி?",
        answer: [
          "'Login' (அல்லது 'Sign In') பகுதியைச் செல்க.",
          "உங்கள் பயனர் பெயர் மற்றும் கடவுச்சொல்லை சம்பந்தப்பட்ட புலங்களில் கொடுங்கள்.",
          "'Sign In' பொத்தானைக் கிளிக் செய்யவும்.",
          "வெற்றிகரமாக உள்நுழைந்த பிறகு, நீங்கள் முகப்புப் பக்கம் அணுகலாம்."
        ]
      },
      {
        question: "நான் கடவுச்சொல்லை மறந்துவிட்டால் என்ன செய்ய வேண்டும்?",
        answer: [
          "'Forgot Password' இணைப்பை உள்நுழைவு பக்கத்தில் கிளிக் செய்யவும்.",
          "உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்.",
          "உங்கள் மின்னஞ்சல் முகவரிக்கு அனுப்பப்பட்ட இணைப்பைத் தொடர்ந்து புதிய கடவுச்சொல்லை அமைக்கவும்."
        ]
      },
      {
        question: "ஸ்கேன் பணிகளை ஆரம்பிப்பது எப்படி?",
        answer: [
          "உள்நுழைந்த பிறகு, Fish Selection Page இல் 'Yellowfin Tuna' அட்டையைத் தேர்ந்தெடுக்கவும்.",
          "'Eye Option' ஐத் தேர்ந்தெடுக்கவும்.",
          "இடத்தை இயக்கவும் மின்னஞ்சல் செய்தியைப் பின்பற்றவும்.",
          "மீனின் கண் பகுதியை தெளிவாக படம் பிடிக்கவும்."
        ]
      },
      {
        question: "ஒரே அமர்வில் மீனின் பல பகுதிகளை ஸ்கேன் செய்ய முடியுமா?",
        answer: [
          "ஆம், ஒவ்வொரு அமர்வுக்கும் தனித்துவமான ID வழங்கப்படுகிறது, மேலும் கண்கள் அல்லது இறைச்சி போன்ற குறிப்பிட்ட பகுதிகளை ஸ்கேன் செய்யலாம்.",
          "ஒரே அமர்வுக்கு பல புகைப்படங்களைச் சேர்க்க முடியும்."
        ]
      },
      {
        question: "நான் ஒரு அமர்வை தொடர்வது எப்படி?",
        answer: [
          "அதே அமர்வை தொடர 'eye' அல்லது மற்றவை ஐ கிளிக் செய்யவும்.",
          "இதன் மூலம், ID மற்றும் இடத்தைப் பயன்படுத்தி பணிகளைச் செய்ய அனுமதி பெறுவீர்கள்."
        ]
      },
      {
        question: "நான் என்னுடைய விபரங்களை புதுப்பிக்க எப்படி?",
        answer: [
          "திரையின் மேல் வலது மூலையில் உங்கள் சுயவிவரத்தைத் தெரிவிக்கும் ஐகான் அல்லது பயனர் பெயரைக் காணலாம்.",
          "அந்தப் பகுதிக்கு செல்க, பின்னர் கைவிடும் பட்டியை திறக்கவும்.",
          "'Profile Settings' அல்லது 'Edit Profile' ஐ கிளிக் செய்து தங்களுடைய தகவல்களை மாற்றவும்."
        ]
      },
      {
        question: "நான் என்னுடைய கணக்கில் இருந்து வெளியேறுவது எப்படி?",
        answer: [
          "திரையின் மேல் வலது மூலையில் உங்கள் சுயவிவரத்தைத் தெரிவிக்கும் ஐகான் அல்லது பயனர் பெயரைக் காணலாம்.",
          "அந்தப் பகுதிக்கு செல்க, பின்னர் கைவிடும் பட்டியை திறக்கவும்.",
          "'Log Out' ஐ தேர்ந்தெடுத்து, வெளியேறுவதற்கான அழுத்தத்தை உறுதிப்படுத்தவும்.",
          "பின்னர் உங்களது கணக்கிலிருந்து வெளியேற்றப்படும் மற்றும் உள்நுழைவு பக்கத்திற்கு திருப்பி விடப்படுவீர்கள்."
        ]
      }
    ]
  };
  
  

  const renderFAQs = () => {
    return faqContent[language].map((faq, index) => (
      <div key={index} className="faq-item">
        <h4>{faq.question}</h4>
        <ul>
          {faq.answer.map((line, i) => (
            <li key={i}>{line}</li>
          ))}
        </ul>
      </div>
    ));
  };

  return (
    <>
    <NavbarTwo />
    <div className='about-area pd-top-120 pd-bottom-120'>
      <div className='container'>
        <div className='row'>
          <div className="col-md-12">
            <h2>FAQ</h2>
            <br/>
    <div className="faq-page">
      {/* Language Selector */}
      <div className="language-selector">
        <label htmlFor="language">Select Language: </label>

        <select
          id="language"
          className="form-control w200"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option value="en">English</option>
          <option value="si">සිංහල</option>
          <option value="ta">தமிழ்</option>
        </select>
      </div>
<br/>

      {/* FAQ Content */}
      <div className="faq-content grey-box ">{renderFAQs()}</div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    <FooterTwo />
  </>

);
};
export default FAQPage;
