import React, { useEffect } from 'react';
import FooterTwo from "../components/FooterTwo";
import NavbarTwo from "../components/NavbarTwo";

const Home = () => {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Navigation Bar Two */}
      <NavbarTwo />
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-12 ">
            <h2>Privacy Policy</h2>
            <br/>
              <div className='grey-box'>
     
      <p>Last updated: September 26, 2024</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use and
        disclosure of Your information when You use the Service and tells You about Your privacy
        rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the Service, You agree
        to the collection and use of information in accordance with this Privacy Policy.
      </p>

      <h3>Interpretation and Definitions</h3>

      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following
        conditions. The following definitions shall have the same meaning regardless of whether they
        appear in singular or in plural.
      </p>

      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
        </li>
        <li>
          <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
        </li>
        <li>
          <p><strong>Application</strong> refers to AIMaluwa, the software program provided by the Company.</p>
        </li>
        <li>
          <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to GeoEDGE (PVT) LTD, 1245, 2 Rajamalwatta Rd, Battaramulla 12138.</p>
        </li>
        <li>
          <p><strong>Country</strong> refers to: Sri Lanka</p>
        </li>
        <li>
          <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
        </li>
        <li>
          <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
        </li>
        <li>
          <p><strong>Service</strong> refers to the Application.</p>
        </li>
        <li>
          <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
        </li>
        <li>
          <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
        </li>
        <li>
          <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
        </li>
      </ul>

      <h3>Collecting and Using Your Personal Data</h3>
      <h3>Types of Data Collected</h3>

      <h4>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain personally identifiable
        information that can be used to contact or identify You. Personally identifiable information
        may include, but is not limited to:
      </p>
      <ul>
        <li>
          <p>Email address</p>
        </li>
        <li>
          <p>First name and last name</p>
        </li>
        <li>
          <p>Usage Data</p>
        </li>
      </ul>

      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
        address), browser type, browser version, the pages of our Service that You visit, the time and
        date of Your visit, the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>

      {/* Continue with the rest of the sections similarly */}
      </div>
    </div>
</div>
</div>
</div>
      {/* Footer Two */}
      <div id="contact">
      <FooterTwo />
      </div>
    </>
  );
};

export default Home;
