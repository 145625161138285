import React, { useState, useEffect } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const NavbarTwo = () => {
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const location = useLocation();

  const menuActive = () => {
    setActive(!active);
  };

  const searchActive = () => {
    setSearchShow(!searchShow);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <button
              onClick={menuActive}
              className={`menu toggle-btn d-block d-lg-none ${
                active ? "open" : ""
              }`}
              data-target="#itech_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className="logo">
            <a href="#home">
              <img src="assets/img/logo.png" alt="logo" />
            </a>
          </div>

          <div
            className={`collapse navbar-collapse ${active ? "sopen" : ""}`}
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                <a href="/#home" onClick={() => setActive(false)}>Home</a>
              </li>
              <li>
                <a href="/#about" onClick={() => setActive(false)}>About</a>
              </li>
              <li>
                <a href="/#services" onClick={() => setActive(false)}>Services</a>
              </li>
              <li>
                <a href="/#testimonials" onClick={() => setActive(false)}>Testimonials</a>
              </li>
              <li>
              <a href="/FAQ" onClick={() => setActive(false)}>FAQ</a> 
              </li>
              <li>
                <a href="/#contact" onClick={() => setActive(false)}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarTwo;
